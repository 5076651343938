import { useEffect, useMemo, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { drawReportCanvas } from './lib'

import s from './ReportScreenCanvas.module.css'

import { ProjectData } from 'shared/types/projects'

import { Report } from 'widgets/report/types'
import { ScreenFragment } from 'entities/assessment'

interface ReportScreenCanvasProps {
  screenId: string
  fragmentId: string
  project: ProjectData
  assessmentId: string | undefined
  report: Report | null
}

const FRAGMENT_SCROLL_OFFSET = 25

export const ReportScreenCanvas = ({
  screenId,
  fragmentId,
  project,
  assessmentId,
  report,
}: ReportScreenCanvasProps) => {
  const [canvasSize, setCanvasSize] = useState<{
    height: number
    width: number
  } | null>(null)
  const canvasEl = useRef<HTMLCanvasElement>(null)
  const wrapperEl = useRef<HTMLDivElement>(null)

  const screen = project.flow.screens.find((screen) => screen.id === screenId)

  const fragments =
    project.totalAssessmentScreenFragments?.[screenId] ||
    project.screenFragments[screenId]

  const fragment = fragments?.find(
    (screenFragment) => screenFragment.id === fragmentId
  )

  const finalFragment = useMemo(() => {
    if (fragment || !report) return fragment

    let screenFragment: ScreenFragment | undefined

    for (let reportScreen of report.screens) {
      const screenVariant = reportScreen.screenVariants.find(
        (item) => item.screenId === screenId
      )

      if (screenVariant) {
        const fragment = screenVariant.fragments.find(
          (item) => item.id === fragmentId
        )

        screenFragment = fragment

        break
      }
    }

    return screenFragment
  }, [fragment, fragmentId, report, screenId])

  useEffect(() => {
    if (canvasEl.current && canvasSize && finalFragment) {
      const canvas = new fabric.Canvas(canvasEl.current, {
        hoverCursor: 'pointer',
        moveCursor: 'pointer',
        height: canvasSize.height,
        width: canvasSize.width,
      })

      drawReportCanvas({
        canvas,
        fragment: finalFragment,
      })

      return () => {
        canvas.dispose()
      }
    }
  }, [canvasSize, finalFragment])

  useEffect(() => {
    if (fragmentId) {
      const fragment = fragments?.find((item) => item.id === fragmentId)

      if (fragment) {
        const scrollOptions = {
          left: fragment.position.x
            ? fragment.position.x - FRAGMENT_SCROLL_OFFSET
            : 0,
          top: fragment.position.y
            ? fragment.position.y - FRAGMENT_SCROLL_OFFSET
            : 0,
        }

        wrapperEl.current?.scrollTo(scrollOptions)
      }
    }
  }, [fragmentId, fragments, canvasSize, assessmentId])

  return (
    <div className={s.wrapper} ref={wrapperEl}>
      <canvas ref={canvasEl}></canvas>
      {screen?.previewImageUrl && (
        <img
          src={screen?.previewImageUrl}
          id="canvas-image"
          alt="canvas"
          className={s.image}
          onLoad={(event) => {
            setCanvasSize({
              height: (event.target as HTMLImageElement).height,
              width: (event.target as HTMLImageElement).width,
            })
          }}
        />
      )}
    </div>
  )
}
