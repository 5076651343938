import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { Heuristic, useHeuristicsAndTags } from 'entities/assessment'
import { useMemo, useState } from 'react'

const filter = createFilterOptions<string>()

interface HeuristicSearchProps {
  selectTags: (tags: string[]) => void
  selectHeuristicId?: (heuristicId: string) => void
  selectedTags: string[]
  heuristics?: Heuristic[]
  placeholder: string
  label: string
  projectId: string
  creatable?: boolean
  fullWidth?: boolean
  onlyTags?: boolean
  isNegative?: boolean
}

export const HeuristicSearch = ({
  selectTags,
  selectedTags,
  selectHeuristicId,
  placeholder,
  label,
  projectId,
  creatable,
  fullWidth,
  onlyTags = false,
  heuristics,
  isNegative = false,
}: HeuristicSearchProps) => {
  const [inputValue, setInputValue] = useState('')

  const { data } = useHeuristicsAndTags(projectId)

  const tagsOptions = useMemo(() => {
    const tags = data?.tags || []

    return [...new Set([...tags, ...selectedTags])].filter(Boolean)
  }, [data?.tags, selectedTags])

  const heuristicOptions = useMemo(() => {
    return (
      heuristics?.map((item) => (isNegative ? item.nameNegative : item.name)) ||
      []
    )
  }, [heuristics, isNegative])

  const hasMatchByTags = onlyTags
    ? true
    : tagsOptions.some((tag) =>
        tag?.toLowerCase().includes(inputValue?.toLowerCase())
      )

  const options = useMemo(() => {
    return hasMatchByTags ? tagsOptions : heuristicOptions
  }, [hasMatchByTags, heuristicOptions, tagsOptions])

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      sx={
        fullWidth
          ? undefined
          : {
              width: '360px',
            }
      }
      fullWidth={fullWidth}
      size="small"
      value={selectedTags}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      onChange={(_, newValue) => {
        if (hasMatchByTags) {
          selectTags(newValue)
        } else {
          const heuristic = data?.heuristics.find(
            (item) =>
              item.name === newValue[0] || item.nameNegative === newValue[0]
          )

          if (heuristic) {
            selectHeuristicId?.(heuristic.id)
          }
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      filterOptions={
        creatable
          ? (options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option)
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue)
              }

              return filtered
            }
          : undefined
      }
    />
  )
}
