import {
  AddScreenFragmentParams,
  ScreenFragment,
  UpdateScreenFragmentParams,
  getAssessmentAttributes,
} from 'entities/assessment'
import { fabric } from 'fabric'
import { ScreenFragmentWithAssessmentType } from './types'
import Color from 'color'

interface LoadImageParams {
  imageUrl: string
  setImage: (value: fabric.Image) => void
}

export const loadImage = ({ imageUrl, setImage }: LoadImageParams) => {
  fabric.Image.fromURL(imageUrl, (oImg) => {
    oImg.set('selectable', false)
    oImg.set('objectCaching', false)
    setImage(oImg)
  })
}

const RECT_TYPE = 'rect'

interface CreateFabricRectParams {
  left: number
  top: number
  width: number
  height: number
  fragment?: ScreenFragment
}

const createFabricRect = ({
  height,
  left,
  top,
  width,
  fragment,
}: CreateFabricRectParams) =>
  new fabric.Rect({
    left,
    top,
    originX: 'left',
    originY: 'top',
    width,
    height,
    angle: 0,
    fill: 'transparent',
    transparentCorners: false,
    selectable: false,
    type: RECT_TYPE,
    stroke: '#33FFFF',
    strokeWidth: 3,
    strokeDashArray: [5, 3],
    opacity: 1,
    data: fragment ? createFragmentData(fragment) : undefined,
  })

const createFragmentData = (fragment: ScreenFragment) => ({
  id: fragment.id,
})

interface CreateMarkerAndRectParams {
  fragment: ScreenFragmentWithAssessmentType
  active: boolean
  screenId: string
}

const MARKER_TYPE = 'marker'

const createMarkerAndRect = ({
  fragment,
  active,
  screenId,
}: CreateMarkerAndRectParams) => {
  const size = 8

  const markerColor =
    fragment.markerAssessmentType === 'multi'
      ? 'gray'
      : Color(getAssessmentAttributes()[fragment.markerAssessmentType].color)
          .darken(0.5)
          .saturate(0.7)
          .string()

  const circle = new fabric.Circle({
    height: size,
    width: size,
    left: fragment.position.x + fragment.width - size,
    top: fragment.position.y - size,
    fill: markerColor,
    originX: 'left',
    originY: 'top',
    radius: size,
    selectable: false,
    type: MARKER_TYPE,
    data: createFragmentData(fragment),
  })

  if (fragment.markerAssessmentType === 'multi') {
    const gradient = new fabric.Gradient({
      type: 'linear',
      gradientUnits: 'pixels', // or 'percentage'
      coords: { x1: 0, y1: 0, x2: circle.width, y2: 0 },
      colorStops: [
        { offset: 0, color: 'red' },
        { offset: 0.5, color: 'yellow' },
        { offset: 1, color: 'green' },
      ],
    })

    circle.set('fill', gradient)
  }

  if (active) {
    const rect = createFabricRect({
      height: fragment.height,
      left: fragment.position.x,
      top: fragment.position.y,
      width: fragment.width,
      fragment,
    })

    rect.set('opacity', 0.5)
    rect.set('selectable', true)
    rect.set('lockRotation', true)
    rect.set('hasRotatingPoint', false)

    return {
      circle,
      rect,
    }
  } else {
    const rect = createFabricRect({
      height: fragment.height,
      left: fragment.position.x,
      top: fragment.position.y,
      width: fragment.width,
      fragment,
    })

    rect.set('opacity', 0.5)
    // rect.set('opacity', 0)
    rect.set('selectable', false)
    rect.set('lockRotation', true)
    rect.set('hasRotatingPoint', false)

    return {
      circle,
      rect,
    }
  }
}

interface DrawCanvasParams {
  canvas: fabric.Canvas
  screenId: string
  fragments: ScreenFragmentWithAssessmentType[]
  activeFragmentId: string | undefined
  setActiveFragmentId: (value: string) => void
  rectRef: React.MutableRefObject<string | null>
  addScreenFragment: (params: AddScreenFragmentParams) => void
  updateScreenFragment: (params: UpdateScreenFragmentParams) => void
  readOnly: boolean
}

export const drawCanvas = ({
  canvas,
  screenId,
  fragments,
  activeFragmentId,
  setActiveFragmentId,
  rectRef,
  addScreenFragment,
  updateScreenFragment,
  readOnly,
}: DrawCanvasParams) => {
  const imgElement = document.getElementById('canvas-image')

  if (imgElement) {
    const imgInstance = new fabric.Image(imgElement as any, {
      left: 0,
      top: 0,
      selectable: false,
    })
    canvas.add(imgInstance)
  }

  fragments
    .filter((item) => !item.root)
    .sort((item) => (item.id === activeFragmentId ? -1 : 1))
    .forEach((fragment) => {
      const fragmentMarker = createMarkerAndRect({
        fragment,
        active: activeFragmentId === fragment.id,
        screenId,
      })

      canvas.add(fragmentMarker.rect)

      if (activeFragmentId === fragment.id) {
        canvas.setActiveObject(fragmentMarker.rect)
      }
    })

  fragments
    .filter((item) => !item.root)
    .forEach((fragment) => {
      const fragmentMarker = createMarkerAndRect({
        fragment,
        active: activeFragmentId === fragment.id,
        screenId,
      })

      canvas.add(fragmentMarker.circle)
    })

  let rect: fabric.Rect | undefined,
    isDown: boolean,
    origX: number,
    origY: number

  canvas.on('mouse:down', function (o) {
    if (o.target?.type === MARKER_TYPE) {
      if (o.target?.data?.id) {
        setActiveFragmentId(o.target?.data?.id)
      }
    } else if (
      o.target?.type === RECT_TYPE &&
      o.target.data?.id === activeFragmentId
    ) {
    } else {
      if (!readOnly) {
        isDown = true
        const pointer = canvas.getPointer(o.e)

        origX = pointer.x
        origY = pointer.y

        rect = createFabricRect({
          left: origX,
          top: origY,
          width: pointer.x - origX,
          height: pointer.y - origY,
        })

        canvas.add(rect)
      }
    }
  })

  canvas.on('mouse:move', function (o) {
    if (!isDown) {
      if (o.target?.type === MARKER_TYPE) {
        if (o.target?.data?.id && o.target?.data?.id !== activeFragmentId) {
          rectRef.current = o.target?.data?.id
          const searchRect = canvas
            .getObjects()
            .find(
              (item) =>
                item.data?.id === o.target?.data?.id && item.type === RECT_TYPE
            )

          searchRect?.set('opacity', 0.5)
          searchRect?.set('strokeDashArray', [5, 3])
          canvas.renderAll()
        }
      } else {
        if (rectRef.current) {
          if (rectRef.current !== activeFragmentId) {
            const searchRect = canvas
              .getObjects()
              .find(
                (item) =>
                  item.data?.id === rectRef.current && item.type === RECT_TYPE
              )
            searchRect?.set('opacity', 0.5)
            // searchRect?.set('opacity', 0)
            canvas.renderAll()
          }

          rectRef.current = null
        }
      }

      return
    }

    const pointer = canvas.getPointer(o.e)

    if (origX > pointer.x) {
      rect?.set({ left: Math.abs(pointer.x) })
    }
    if (origY > pointer.y) {
      rect?.set({ top: Math.abs(pointer.y) })
    }

    rect?.set({ width: Math.abs(origX - pointer.x) })
    rect?.set({ height: Math.abs(origY - pointer.y) })

    canvas.renderAll()
  })

  canvas.on('mouse:up', function (o) {
    isDown = false

    if (!readOnly) {
      if ((rect?.height as number) > 10 && (rect?.width as number) > 10) {
        addScreenFragment({
          screenId,
          dimensions: {
            height: rect?.height as number,
            width: rect?.width as number,
          },
          position: {
            x: rect?.left as number,
            y: rect?.top as number,
          },
          onSuccess(id) {
            setActiveFragmentId(id)
          },
        })
      }
    }

    if (rect) {
      canvas.remove(rect)
      rect = undefined
    }
  })

  if (!readOnly) {
    canvas.on('object:modified', (event) => {
      if (event.target?.type === RECT_TYPE && event.target.data?.id) {
        updateScreenFragment({
          screenId,
          fragmentId: event.target.data?.id,
          dimensions: {
            height:
              (event.target.height as number) * (event.target.scaleY as number),
            width:
              (event.target.width as number) * (event.target.scaleX as number),
          },
          position: {
            x: event.target.left as number,
            y: event.target.top as number,
          },
          onSuccess: () => setActiveFragmentId(event.target?.data?.id),
        })
      }
    })
  }

  canvas.renderAll()
}
