import i18n from 'shared/i18n/i18n'
import { Option } from './types'

export const getFindTypeOptions = (): Option[] => [
  {
    name: i18n.t('usabilityGood'),
    value: 'ux-good',
  },
  {
    name: i18n.t('usabilityProblem'),
    value: 'ux-problem',
  },
  {
    name: i18n.t('businessTask'),
    value: 'task',
  },
  {
    name: i18n.t('bug'),
    value: 'bug',
  },
]

export const getCriticalityOptions = (): Option[] => [
  {
    name: i18n.t('low'),
    value: 'good',
  },
  {
    name: i18n.t('medium'),
    value: 'medium',
  },
  {
    name: i18n.t('high'),
    value: 'bad',
  },
]

export const getReportAssessmentFilterOptions = (): Option[] => [
  {
    name: i18n.t('usabilityGood'),
    value: 'ux-good',
  },
  {
    name: i18n.t('businessTask'),
    value: 'task',
  },
  {
    name: i18n.t('bug'),
    value: 'bug',
  },
  {
    value: 'ux-problem-high',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'high'
    )})`,
  },
  {
    value: 'ux-problem-medium',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'medium'
    )})`,
  },
  {
    value: 'ux-problem-low',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'low'
    )})`,
  },
]
