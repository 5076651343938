import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import {
  Report,
  ReportFilterType,
  ReportScreenVariantFragment,
} from 'widgets/report/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import s from './ReportFragment.module.css'
import { ReportAssessmentFilter } from '../ReportAssessmentFilter/ReportAssessmentFilter'
import { ReportFind } from '../ReportFind'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { isFindDisabled } from './lib'
import { Assessment, useRemoveFinalReportAssessment } from 'entities/assessment'
import { useProject, useProjectId } from 'shared/model/projects'

interface ReportFragmentProps {
  expanded: boolean
  onExpandedChange: VoidFunction
  formattedNumber: string
  fragment: ReportScreenVariantFragment
  selectedAssessmentId: string | null
  setSelectedAssessmentId: (value: string | null) => void
  report: Report
  screenId: string
  assessmentFilter: ReportFilterType
  totalAssessmentType: string | undefined
  toggleInProgressAssessmentId: (
    id: string | string[],
    assessmentType: string
  ) => void
  inProgressAssessmentIds: string[] | undefined
  isTotalAssessment: boolean
  isFinalReportTab?: boolean
  isFinalReportAssessmentsHidden?: boolean
  setTab: (newTab: 'assessment' | 'totalAssessment') => void
}

export const ReportFragment = ({
  expanded,
  formattedNumber,
  fragment,
  onExpandedChange,
  selectedAssessmentId,
  report,
  setSelectedAssessmentId,
  assessmentFilter,
  totalAssessmentType,
  isTotalAssessment,
  toggleInProgressAssessmentId,
  inProgressAssessmentIds,
  isFinalReportAssessmentsHidden = false,
  isFinalReportTab = false,
  setTab,
  screenId,
}: ReportFragmentProps) => {
  const isAllFilter = assessmentFilter === 'all'

  const projectId = useProjectId()
  const { data: project } = useProject()

  const filteredAssessments = (
    isAllFilter
      ? fragment.assessments
      : fragment.assessments.filter((assessment) =>
          assessmentFilter.includes(assessment.findType as ReportFilterType)
        )
  ).filter((assessment) =>
    isFinalReportAssessmentsHidden
      ? !report.finalReportProgress.finalReportRelatedAssessmentIds.includes(
          assessment.id
        )
      : true
  )

  const removeFinalReportAssessment = useRemoveFinalReportAssessment()

  if (!filteredAssessments.length || !projectId) return null

  const isCustomFragment = !!fragment.isCustom

  const totalAssessmentDisabled = inProgressAssessmentIds?.length === 3

  const groupedAssessments = filteredAssessments.filter(
    (item) => item.hasHeuristicGroup
  )
  const assessmentsGroups = groupedAssessments.reduce((map, item) => {
    const mapValue = map.get(item.heuristicId) || []

    map.set(item.heuristicId, mapValue.concat(item))

    return map
  }, new Map<string, Assessment[]>())
  const assessmentsGroupKeys = [...assessmentsGroups.keys()]

  const ungroupedAssessments = filteredAssessments.filter(
    (item) => !item.hasHeuristicGroup
  )

  const isExcluded = (assessmentId: string) =>
    project?.excludedAssessmentIds?.includes(assessmentId)

  return (
    <Accordion
      className={s.accordion}
      expanded={expanded}
      onChange={onExpandedChange}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={
              expanded
                ? {
                    fill: '#2C9BF0',
                  }
                : undefined
            }
          />
        }
        className={s.accordionSummary}
      >
        <div className={s.accordionSummaryContent}>
          <div>
            <Typography variant="subtitle2" color="gray">
              {formattedNumber}
            </Typography>
            <div className={s.nameWithIcon}>
              {isCustomFragment && <QrCodeIcon />}

              <Typography variant="h6" className={s.title}>
                {fragment.name}
              </Typography>
            </div>
          </div>
          <ReportAssessmentFilter
            assessmentFilter={assessmentFilter}
            assessments={fragment.totalAssessments}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails className={s.accordionDetails}>
        {assessmentsGroupKeys.map((heuristicId) => {
          const groupAssessments = assessmentsGroups.get(heuristicId)

          if (!groupAssessments) return null

          const groupAssessmentsLength = groupAssessments.length

          return groupAssessments.map((assessment, index) => {
            const inProgressTotalAssessment = inProgressAssessmentIds?.includes(
              assessment.id
            )

            const getDashedLine = (): 'top' | 'top-bottom' | 'bottom' => {
              if (index === 0) return 'bottom'
              if (index === groupAssessmentsLength - 1) return 'top'
              return 'top-bottom'
            }

            return (
              <ReportFind
                key={assessment.id}
                active={
                  assessment.id === selectedAssessmentId ||
                  !!inProgressTotalAssessment
                }
                isFinalReportTab={isFinalReportTab}
                heuristics={report.heuristics}
                assessment={assessment}
                projectId={projectId}
                onClick={() =>
                  toggleInProgressAssessmentId(
                    groupAssessments.map((item) => item.id),
                    assessment.findType
                  )
                }
                onWholePlateClick={() => null}
                isTotalAssessment={isTotalAssessment}
                disabled={
                  isTotalAssessment && !inProgressTotalAssessment
                    ? isFindDisabled({
                        assessment,
                        totalAssessmentType,
                      }) || totalAssessmentDisabled
                    : false
                }
                inProgressTotalAssessment={inProgressTotalAssessment}
                dashedLine={getDashedLine()}
                excluded={isExcluded(assessment.id)}
              />
            )
          })
        })}
        {ungroupedAssessments.map((assessment) => {
          const inProgressTotalAssessment = inProgressAssessmentIds?.includes(
            assessment.id
          )

          return (
            <ReportFind
              key={assessment.id}
              active={
                assessment.id === selectedAssessmentId ||
                !!inProgressTotalAssessment
              }
              isFinalReportTab={isFinalReportTab}
              heuristics={report.heuristics}
              assessment={assessment}
              projectId={projectId}
              onWholePlateClick={
                isFinalReportTab
                  ? () => {
                      setSelectedAssessmentId(
                        assessment.id === selectedAssessmentId
                          ? null
                          : assessment.id
                      )
                    }
                  : () => null
              }
              onClick={() => {
                if (isTotalAssessment) {
                  if (isFinalReportTab) {
                    setTab('assessment')

                    if (assessment.relatedAssessmentIds) {
                      toggleInProgressAssessmentId(
                        assessment.relatedAssessmentIds,
                        assessment.findType
                      )
                      removeFinalReportAssessment({
                        assessmentId: assessment.id,
                        fragmentId: fragment.id,
                        screenId,
                      })
                    }
                  } else {
                    toggleInProgressAssessmentId(
                      assessment.id,
                      assessment.findType
                    )
                  }
                } else {
                  setSelectedAssessmentId(
                    assessment.id === selectedAssessmentId
                      ? null
                      : assessment.id
                  )
                }
              }}
              isTotalAssessment={isTotalAssessment}
              disabled={
                isTotalAssessment && !inProgressTotalAssessment
                  ? isFindDisabled({
                      assessment,
                      totalAssessmentType,
                    }) || totalAssessmentDisabled
                  : false
              }
              inProgressTotalAssessment={inProgressTotalAssessment}
              excluded={isExcluded(assessment.id)}
            />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
