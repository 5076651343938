import { Box, Button, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FindSelect } from './FindSelect'
import { getCriticalityOptions, getFindTypeOptions } from './lib'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultFindFormValues, findValidationSchema } from './form'

import s from './FindForm.module.css'

import { HeuristicSearch } from '../heuristic/search'
import { AssessmentPanelFilter } from '../filter'
import { useEffect, useState } from 'react'
import { useProjectId } from 'shared/model/projects'
import { getHeuristicFilterAttributes } from 'widgets/assessment/panel/config'
import {
  Assessment,
  useAddAssessment,
  useDeleteAssessment,
  useEditAssessment,
  useHeuristics,
  useScreenFragments,
} from 'entities/assessment'
import { HeuristicWithButton } from './HeuristicWithButton'
import classNames from 'classnames'

interface FindFormProps {
  screenId: string
  fragmentId: string
  activeHeuristicId: string
  setActiveHeuristicId: (value: string) => void
  onListPlacement?: boolean
  assessment?: Assessment
}

const EDIT_SAVE_DELAY = 400

export const FindForm = ({
  fragmentId,
  screenId,
  setActiveHeuristicId,
  activeHeuristicId,
  assessment,
  onListPlacement = false,
}: FindFormProps) => {
  const [selectedHeuristicTags, selectHeuristicTags] = useState<string[]>([])
  const [selectedHeuristicFilter, selectHeuristicFilter] =
    useState<string>('favorite')

  const { t } = useTranslation()

  const { register, handleSubmit, formState, setValue, watch, reset } = useForm(
    {
      resolver: yupResolver(findValidationSchema),
      defaultValues: assessment
        ? {
            criticality: assessment.type || '',
            findDescription: assessment.comment || '',
            findName: assessment.name || '',
            recommendation: assessment.recommendation || '',
            tags: assessment.tags || [],
            findType: assessment.findType || '',
            heuristicId: assessment.heuristicId || undefined,
          }
        : defaultFindFormValues,
      mode: 'onSubmit',
    }
  )

  const selectedHeuristicId = watch('heuristicId')
  const findType = watch('findType')
  const findName = watch('findName')
  const recommendation = watch('recommendation')
  const findDescription = watch('findDescription')
  const criticality = watch('criticality')
  const tags = watch('tags')

  const projectId = useProjectId()

  const heuristics = useHeuristics({
    tags: selectedHeuristicTags,
    projectId,
  })

  const fragments = useScreenFragments({ screenId })
  const fragment = fragments?.find((item) => item.id === fragmentId)
  const assessments = fragment?.assessments

  const filteredHeuristics = heuristics?.filter(
    (heuristic) =>
      (selectedHeuristicFilter === 'favorite' ? heuristic.favorite : true) &&
      selectedHeuristicId !== heuristic.id &&
      assessments?.every(
        (itemAssessment) => itemAssessment.heuristicId !== heuristic.id
      ) &&
      (heuristic.evaluationArea === 'screen_and_fragment' ||
      !heuristic.evaluationArea
        ? true
        : fragment?.root
        ? heuristic.evaluationArea === 'screen'
        : heuristic.evaluationArea === 'fragment')
  )

  const addAssessment = useAddAssessment()
  const editAssessment = useEditAssessment()
  const deleteAssessment = useDeleteAssessment()

  const selectedHeuristic = heuristics?.find(
    (heuristicObject) => heuristicObject.id === selectedHeuristicId
  )

  const submitEnabled = Boolean(selectedHeuristicId || findName) || !!assessment

  useEffect(() => {
    if (findType === 'bug') {
      setValue('heuristicId', '')
      setValue('tags', [])
    }
  }, [findType, setValue])

  useEffect(() => {
    if (assessment?.id) {
      const isHeuristicsEnabled =
        findType === 'ux-problem' ||
        findType === 'task' ||
        findType === 'ux-good'

      const newAssessment = {
        ...assessment,
        comment: findDescription,
        findType: findType,
        type: criticality,
        heuristicId: isHeuristicsEnabled ? selectedHeuristicId || '' : '',
        recommendation: recommendation,
        name: findName,
        tags: tags || [],
      }

      const isSameData =
        JSON.stringify(newAssessment) === JSON.stringify(assessment)

      if (!isSameData) {
        const timer = setTimeout(() => {
          editAssessment({
            assessment: {
              ...assessment,
              comment: findDescription,
              findType: findType,
              type: criticality,
              heuristicId: isHeuristicsEnabled ? selectedHeuristicId || '' : '',
              recommendation: recommendation,
              name: findName,
              tags: tags || [],
            },
            screenId,
            fragmentId,
          })
        }, EDIT_SAVE_DELAY)

        return () => clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    criticality,
    editAssessment,
    findDescription,
    findName,
    findType,
    fragmentId,
    recommendation,
    screenId,
    selectedHeuristicId,
    tags,
  ])

  return (
    <form
      noValidate
      onSubmit={handleSubmit(
        (values) => {
          if (assessment) {
            deleteAssessment({
              assessmentId: assessment.id,
              fragmentId,
              screenId,
            })
          } else {
            const isHeuristicsEnabled =
              values.findType === 'ux-problem' ||
              values.findType === 'task' ||
              values.findType === 'ux-good'

            addAssessment({
              fragmentId,
              screenId,
              assessment: {
                comment: values.findDescription,
                findType: values.findType,
                type: values.criticality,
                heuristicId: isHeuristicsEnabled
                  ? values.heuristicId || ''
                  : '',
                recommendation: values.recommendation,
                name: values.findName,
                tags: values.tags || [],
                user: {
                  id: 'user_id',
                  fio: 'Федор Мохнатый',
                },
              },
            })
            reset()
          }
        },
        () => {
          if (assessment) {
            deleteAssessment({
              assessmentId: assessment.id,
              fragmentId,
              screenId,
            })
          }
        }
      )}
      className={classNames(s.form, {
        [s.multiple]: onListPlacement,
      })}
    >
      {(findType === 'ux-problem' ||
        findType === 'task' ||
        findType === 'ux-good') &&
        !assessment && (
          <>
            <div className={s.searchAndFilter}>
              {projectId && (
                <HeuristicSearch
                  selectTags={selectHeuristicTags}
                  selectedTags={selectedHeuristicTags}
                  placeholder={t('enterTag')}
                  label={t('searchHeuristicsByTags')}
                  projectId={projectId}
                  selectHeuristicId={(heuristicId) =>
                    setValue('heuristicId', heuristicId, { shouldDirty: true })
                  }
                  heuristics={filteredHeuristics}
                />
              )}
              <AssessmentPanelFilter
                selectedFilters={[selectedHeuristicFilter]}
                selectFilters={(filters) => selectHeuristicFilter(filters[0])}
                filterAttributes={getHeuristicFilterAttributes()}
              />
            </div>
            <Box
              sx={{
                mt: 2,
                maxHeight: '160px',
                overflowY: 'auto',
                flexWrap: 'nowrap',
                mb: 2,
              }}
            >
              {filteredHeuristics?.map((heuristic) => (
                <HeuristicWithButton
                  key={heuristic.id}
                  negative={findType === 'ux-problem'}
                  nameNegative={heuristic.nameNegative}
                  favorite={heuristic.favorite}
                  active={activeHeuristicId === heuristic.id}
                  mode="add"
                  name={heuristic.name}
                  disabled={Boolean(selectedHeuristicId)}
                  onTextClick={() =>
                    setActiveHeuristicId(
                      activeHeuristicId === heuristic.id ? '' : heuristic.id
                    )
                  }
                  onButtonClick={() =>
                    setValue('heuristicId', heuristic.id, { shouldDirty: true })
                  }
                />
              ))}
            </Box>
          </>
        )}
      <FindSelect
        label={t('findType')}
        value={findType}
        onChange={(value) =>
          setValue('findType', value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        options={getFindTypeOptions()}
        error={!!formState.errors.findType}
        disabled={!!assessment}
      />
      {selectedHeuristic && (
        <HeuristicWithButton
          active={activeHeuristicId === selectedHeuristic.id}
          favorite={selectedHeuristic.favorite}
          negative={findType === 'ux-problem'}
          nameNegative={selectedHeuristic.nameNegative}
          mode="remove"
          name={selectedHeuristic.name}
          onTextClick={() =>
            setActiveHeuristicId(
              activeHeuristicId === selectedHeuristic.id
                ? ''
                : selectedHeuristic.id
            )
          }
          onButtonClick={() =>
            setValue('heuristicId', '', { shouldDirty: true })
          }
          buttonHidden={!!assessment}
        />
      )}
      <TextField
        sx={{
          mb: 2,
          mt: 1,
        }}
        label={t('findName')}
        id="findName"
        size="small"
        fullWidth
        {...register('findName')}
        error={!!formState.errors.findName}
      />
      <TextField
        label={t('findDescription')}
        size="small"
        multiline
        rows={3}
        fullWidth
        sx={{
          mb: 2,
          mt: 1,
        }}
        {...register('findDescription')}
        error={!!formState.errors.findDescription}
      />
      {findType !== 'ux-good' && findType !== 'bug' && (
        <FindSelect
          label={t('criticality')}
          value={watch('criticality')}
          onChange={(value) =>
            setValue('criticality', value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          options={getCriticalityOptions()}
          error={!!formState.errors.criticality}
        />
      )}
      <TextField
        label={t('recommendation')}
        size="small"
        multiline
        rows={3}
        fullWidth
        sx={{
          mb: 3,
          mt: 1,
        }}
        {...register('recommendation')}
        error={!!formState.errors.recommendation}
      />
      {projectId && findType !== 'bug' && (
        <HeuristicSearch
          creatable
          selectTags={(tags) => setValue('tags', tags, { shouldDirty: true })}
          selectedTags={watch('tags') || []}
          placeholder={t('enterTag')}
          label={t('tags')}
          projectId={projectId}
          fullWidth
          onlyTags
        />
      )}

      <Button
        size="large"
        className={s.submitButton}
        type="submit"
        variant="contained"
        color={assessment ? 'error' : 'primary'}
        disabled={!submitEnabled}
        sx={{
          mt: 2,
        }}
      >
        {assessment ? t('removeFind') : t('addFind')}
      </Button>
    </form>
  )
}
