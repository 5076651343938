import {
  ReportAssessmentType,
  ReportAssessments,
  ReportFilterType,
} from 'widgets/report/types'
import classNames from 'classnames'

import s from './ReportAssessmentFilter.module.css'
import { getHeightPercentByCount } from './lib'
import { Tooltip } from '@mui/material'
import { getReportAssessmentFilterOptions } from 'features/assessment/find/lib'

interface ReportAssessmentFilterProps {
  assessments: ReportAssessments
  assessmentFilter: ReportFilterType
}

export const ReportAssessmentFilter = ({
  assessmentFilter,
  assessments,
}: ReportAssessmentFilterProps) => {
  const renderItem = (type: ReportAssessmentType) => {
    // const count = Math.round(Math.random() * 35)
    const count = assessments[type]
    const isActiveFilter =
      assessmentFilter === 'all' || type.includes(assessmentFilter)
    const heightPercent = getHeightPercentByCount(count)

    const filterLabel =
      getReportAssessmentFilterOptions().find((item) => item.value === type)
        ?.name || ''

    return (
      <div
        className={classNames(s.item, s[type], {
          [s.activeItem]: isActiveFilter,
          [s.disabledItem]: count === 0,
        })}
      >
        <Tooltip placement="top" title={`${filterLabel}: ${count}`}>
          <div
            className={s.column}
            style={{
              height: `${heightPercent}%`,
            }}
          />
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={s.wrapper}>
      {renderItem('ux-good')}
      {renderItem('ux-problem-low')}
      {renderItem('ux-problem-medium')}
      {renderItem('ux-problem-high')}
      {renderItem('task')}
      {renderItem('bug')}
    </div>
  )
}
