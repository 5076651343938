import { IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import s from './HeuristicWithButton.module.css'
import classNames from 'classnames'
import RemoveIcon from '@mui/icons-material/Remove'
import StarIcon from '@mui/icons-material/Star'
import { MouseEventHandler } from 'react'

interface HeuristicWithButtonProps {
  active?: boolean
  name: string
  nameNegative?: string
  favorite?: boolean
  onButtonClick?: MouseEventHandler<HTMLButtonElement>
  onTextClick: MouseEventHandler<HTMLDivElement>
  mode?: 'add' | 'remove'
  negative?: boolean
  disabled?: boolean
  buttonHidden?: boolean
  withoutButton?: boolean
}

export const HeuristicWithButton = ({
  active = false,
  mode,
  name,
  onButtonClick,
  onTextClick,
  disabled = false,
  buttonHidden = false,
  favorite = false,
  negative,
  nameNegative,
  withoutButton = false,
}: HeuristicWithButtonProps) => {
  const negativeName = nameNegative || name

  return (
    <div className={s.wrapper}>
      {!withoutButton && (
        <IconButton
          disabled={disabled}
          onClick={onButtonClick}
          className={classNames(s.button, {
            [s.hiddenButton]: buttonHidden,
          })}
        >
          {mode === 'remove' ? (
            <RemoveIcon fontSize="large" />
          ) : (
            <AddIcon fontSize="large" />
          )}
        </IconButton>
      )}
      <div className={s.textWrapper} onClick={onTextClick}>
        {favorite && <StarIcon className={s.favoriteIcon} />}
        <Typography
          className={classNames(s.text, {
            [s.active]: active,
          })}
        >
          {negative ? negativeName : name}
        </Typography>
      </div>
    </div>
  )
}
